import axios, { AxiosError } from 'axios';

async function getAnimais(filters: ApiFiltersType, token: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/animais/`,
      params: {
        ...filters,
      }
    });
    
    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function getFemeasAptas(filters: ApiFiltersType, token: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/animais/femeasaptas`,
      params: {
        ...filters,
      }
    });
    
    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

export { getAnimais, getFemeasAptas };
