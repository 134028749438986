const hiddenColumnsTables = {
  partos: {
    camDescricao: false
  },
  nascimentos: {
    camDescricao: false,
    catNome: false
  },
  concepcoes: {
    camDescricao: false,
    catNome: false
  },
  desmamesOcorridos: {
    camDescricao: false
  },
  protocolosCadastrados: {
  },
  desmamadosPorSemen: {
  },
  femeasExpostas: {
    idadeMeses: false,
    pesoAtual: false
  }
}

export default hiddenColumnsTables
