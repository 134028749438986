import axios from 'axios';

async function getReproducoes(filters: ApiFiltersType, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/animais/reproducao`,
      params: {
        ...filters,
      }
    });
    return response.data
  } catch (error) {
    console.error(error);
  }
}

async function getFemeasExpostas(filters: ApiFiltersType, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/animais/expostas`,
      
      params: {
        ...filters,
      }
    });
    return response.data
  } catch (error) {
    window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/login`
    console.error(error);
  }
}

export { getReproducoes, getFemeasExpostas };
