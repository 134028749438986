import dayjs from "dayjs";
import React, { useContext, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR, es } from 'date-fns/locale';
import { LanguageContext } from '../App';
import { ReactComponent as CalendarIcon } from '../assets/imgs/calendarIcon.svg'
import { Grid } from "@mui/material";
registerLocale('pt', ptBR);
registerLocale('es', es)

interface SelectProps {
  handleChange?: any,
  handleClear?: any,
  handleClickOutside?: any,
  placeholder: string,
  startDate: Date | null,
  endDate: Date | null,
  onlyMonths: boolean | undefined,
  dateFormat: string,
  limitMaxDate: boolean,
  selectRange?: boolean,
  readOnly?: boolean,
  isValid?: boolean;
}

interface DatePickerHeaderProps {
  date: Date
  decreaseMonth: any,
  increaseMonth: any,
  prevMonthButtonDisabled: any,
  nextMonthButtonDisabled: any,
  decreaseYear: any,
  increaseYear: any,
  prevYearButtonDisabled: any,
  nextYearButtonDisabled: any,
}

export default function DataPickerCustom(props: SelectProps) {
  const {
    handleChange,
    handleClear,
    handleClickOutside,
    startDate,
    endDate,
    placeholder,
    onlyMonths,
    dateFormat,
    limitMaxDate,
    selectRange = true,
    readOnly = false,
  } = props;
  const { translations, language } = useContext(LanguageContext);
  const [ calendarState, setCalendarState ] = useState<boolean | undefined>(false);

  const mesEnum = translations ? [
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [];

  const mesCompletoEnum = translations ? [
    translations["lbl.react.janeiro"],
    translations["lbl.react.fevereiro"],
    translations["lbl.react.marco"],
    translations["lbl.react.abril"],
    translations["lbl.react.maio"],
    translations["lbl.react.junho"],
    translations["lbl.react.julho"],
    translations["lbl.react.agosto"],
    translations["lbl.react.setembro"],
    translations["lbl.react.outubro"],
    translations["lbl.react.novembro"],
    translations["lbl.react.dezembro"],
  ] : [];
  
  const handleValue = () => {
    const startDay = startDate ? startDate.getDate() : ""
    const startMonth = startDate ? mesEnum[startDate.getMonth()] : ""
    const startYear = startDate ? startDate.getFullYear() : ""
    const endDay = endDate ? endDate.getDate() : ""
    const endMonth = endDate ? mesEnum[endDate.getMonth()] : ""
    const endYear = endDate ? endDate.getFullYear() : ""
    if (startDate && onlyMonths) return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
    else if (startDate && !selectRange) return `${startDay} ${startMonth} ${startYear}`
    else if (startDate) return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`
    else return ""
  }

  const handleMaxDate = () => {
    const localStartDate = !startDate ?  dayjs().toDate() : startDate
    const maxPickabledDate = dayjs().add(365, 'days').toDate()
    const maxDate = dayjs(localStartDate).add(365, 'days').toDate()

    const today = dayjs().toDate()
    if (maxDate > today && limitMaxDate) return today
    if (maxDate > maxPickabledDate) return maxPickabledDate
    else return maxDate
  }

  const handleOpenCalendar = () => {
    handleClear()
    setCalendarState(true)
  }

  const handleInternalChange = ([newStartDate, newEndDate]: [newStartDate: Date, newEndDate: Date]) => {
    if (!selectRange) setCalendarState(false)
    if (newEndDate !== null) setCalendarState(false)
    handleChange([newStartDate, newEndDate])
  }

  const handleInternalClear = () => {
    handleClear()
  }

  const handleInternalClickOutside = () => {
    setCalendarState(false)
    handleClickOutside()
  }

  const handleInputClick = () => {
    if (!selectRange) handleClear()
    setCalendarState(true)
  }

  const renderCustomHeader = (props: DatePickerHeaderProps) => {
    const { date, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <button className="react-datepicker__navigation react-datepicker__navigation--previous" onClick={decreaseYear} disabled={prevYearButtonDisabled}>
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Year</span>
            </button>
          </div>
          <div style={{fontSize: '16px', fontWeight: '700'}}>
           { new Date(date).getFullYear() }
          </div>
          <div>
            <button className="react-datepicker__navigation react-datepicker__navigation--next" onClick={increaseYear} disabled={nextYearButtonDisabled}>
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Previous Year</span>
            </button>
          </div>
        </div>
        { !onlyMonths &&
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button style={{top: '29px', left: '70px'}} className="react-datepicker__navigation react-datepicker__navigation--previous" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Year</span>
              </button>
            </div>
            <div style={{fontSize: '16px'}}>
              { mesCompletoEnum[new Date(date).getMonth()] }
            </div>
            <div>
              <button style={{top: '29px', right: '70px'}} className="react-datepicker__navigation react-datepicker__navigation--next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Previous Year</span>
              </button>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <div className="select-custom_icon" onClick={readOnly ? undefined : handleOpenCalendar}>
        <CalendarIcon />
      </div>
      <DatePicker
        readOnly={readOnly}
        renderCustomHeader={renderCustomHeader}
        className="select-custom_date"
        placeholderText={placeholder}
        selected={null}
        onChange={handleInternalChange}
        open={calendarState}
        onInputClick={readOnly ? undefined : handleInputClick}
        selectsRange
        startDate={startDate}
        onCalendarOpen={handleInternalClear}
        onClickOutside={handleInternalClickOutside}
        endDate={endDate}
        dateFormat={dateFormat}
        showMonthYearPicker={onlyMonths}
        locale={language}
        value={handleValue()}
        minDate={null}
        maxDate={handleMaxDate()}
      />

    </Grid>
  );
};